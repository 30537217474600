var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-table',{ref:"table",attrs:{"caption":_vm.tableCaption,"height":"calc(100vh - 194px)","columns":_vm.columns,"actions":_vm.tableActions,"url":_vm.tableUrl,"params":_vm.tableParams},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center"},[_c('span',[_vm._v(_vm._s(item.title))]),(item.secrets.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"tw-ml-2",attrs:{"x-small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fal fa-key")])]}}],null,true)},[_c('span',[_vm._v("Secrets available")])]):_vm._e()],1)]}},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"primary","to":{
                    name: 'assets.create',
                    query: _vm.$route.query,
                }}},[_c('v-icon',{attrs:{"left":"","dense":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add ")],1)]},proxy:true}])}),_c('details-modal'),_c('secrets-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }