var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"temporary":"","right":"","app":"","width":"100%","stateless":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","width":"1"}})],1),(! _vm.loading && _vm.show)?[_c('v-card',{attrs:{"flat":""}},[_c('app-table',{ref:"table",attrs:{"caption":((_vm.asset.title) + " Secrets"),"height":"calc(100vh - 130px)","columns":_vm.columns,"actions":_vm.tableActions,"url":("companies/assets/" + (_vm.$route.params.assetId) + "/secrets"),"params":_vm.tableParams},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
                            _vm.$route.meta.parent === 'assets.secrets' ||
                            _vm.$route.name === 'assets.secrets'
                        )?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"primary","to":{
                            name: 'assets.secrets.create',
                            query: _vm.$route.query,
                            params: {
                                assetId: _vm.$route.params.assetId,
                            }
                        }}},[_c('v-icon',{attrs:{"left":"","dense":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add ")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"red","dark":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":"","dense":""}},[_vm._v("fal fa-times-circle")]),_vm._v(" Close ")],1)]},proxy:true}],null,false,3804510993)})],1),_c('add-modal',{on:{"success":function($event){return _vm.$refs.table.init()}}}),_c('edit-modal',{on:{"success":function($event){return _vm.$refs.table.init()}}}),_c('details-modal')]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }